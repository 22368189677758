<template>
  <div class="case-creation__step case-creation__Case-information">
    <div class="case-creation__card">
      <div class="case-creation__heading edit-view">
        <div class="title-with-icon">
          <span class="icon">
            <img
              src="@/assets/images/icons/icon-case-info.svg"
              alt="icon"
              class="img-fluid"
            />
          </span>
          <h3 class="title">Case Information</h3>
        </div>
      </div>
      <div class="case-creation__body pb-0">
        <el-row :gutter="15">
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div
              class="input-value view-cases"
              v-if="getPendingOrderDetails && getPendingOrderDetails.case_types"
            >
              <h4 class="name">Case Type</h4>
              <ul>
                <li
                  v-for="(caseType, index) of getPendingOrderDetails.case_types"
                  :key="index"
                >
                  {{ caseTypeMap[caseType] }}
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Order Date</h4>
              <p class="value">
                {{
                  getPendingOrderDetails.ordering_date
                    ? formatDate(getPendingOrderDetails.ordering_date)
                    : "--"
                }}
              </p>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Ordering Facility</h4>
              <p class="value">
                {{
                  getPendingOrderDetails.hospital
                    ? getPendingOrderDetails.hospital.name
                    : "--"
                }}
              </p>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Ordering Facility Branch</h4>
              <p class="value">
                {{
                  getPendingOrderDetails.hospital_branch
                    ? getPendingOrderDetails.hospital_branch.display_name
                    : "--"
                }}
              </p>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Ordering Physician</h4>
              <p class="value">
                {{
                  getPendingOrderDetails.ordering_physician
                    ? getPendingOrderDetails.ordering_physician.name
                    : "--"
                }}
              </p>
            </div>
          </el-col>

          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Referring Physician</h4>
              <p class="value">
                {{
                  getPendingOrderDetails.referring_physician
                    ? getPendingOrderDetails.referring_physician.name
                    : "--"
                }}
              </p>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Bill To</h4>
              <p class="value">
                {{
                  getPendingOrderDetails.billing_type
                    ? getPendingOrderDetails.billing_type
                    : "--"
                }}
              </p>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Order ID</h4>
              <p class="value">
                {{
                  getPendingOrderDetails.order_id
                    ? getPendingOrderDetails.order_id
                    : "--"
                }}
              </p>
            </div>
          </el-col>
          <el-col :xl="12" :lg="16" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases icd-codes">
              <div class="indicatives">
                <h4 class="name">ICD-10 (Indicative)</h4>
                <ul
                  v-if="
                    getPendingOrderDetails &&
                    getPendingOrderDetails.icd_10_codes
                  "
                >
                  <li
                    class="value"
                    v-for="(
                      icd_10_code, index
                    ) in getPendingOrderDetails.icd_10_codes"
                    :key="index"
                  >
                    {{ icd_10_code }}
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Collection Date</h4>
              <p class="value">
                {{
                  getPendingOrderDetails.collection_date
                    ? momentWithTimezone(
                        getPendingOrderDetails.collection_date,
                        "MM-DD-yyyy"
                      )
                    : "--"
                }}
              </p>
            </div>
          </el-col>
          <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
            <div class="input-value view-cases">
              <h4 class="name">Temperature</h4>
              <p class="value">
                {{
                  getPendingOrderDetails.temperature
                    ? getPendingOrderDetails.temperature
                    : "--"
                }}
              </p>
            </div>
          </el-col>
          <el-col :xl="6" :lg="8" :md="12">
            <div class="input-value view-cases">
              <div
                class="speciment-type-view"
                v-if="
                  getPendingOrderDetails && getPendingOrderDetails.sample_types
                "
              >
                <div>
                  <h4 class="name">Specimen Types</h4>
                  <ul
                    class="case_type"
                    v-if="
                      getPendingOrderDetails.sample_types &&
                      getPendingOrderDetails.sample_types.length
                    "
                  >
                    <li
                      class="value"
                      v-for="(
                        sampleType, index
                      ) in getPendingOrderDetails.sample_types"
                      :key="index"
                    >
                      <span>
                        {{ caseTypeMap[sampleType.case_type] }}
                      </span>
                    </li>
                  </ul>
                  <ul v-else>
                    --
                  </ul>
                </div>
                <div>
                  <h4 class="name">&nbsp;</h4>
                  <ul
                    class="speciment_type"
                    v-if="
                      getPendingOrderDetails.sample_types &&
                      getPendingOrderDetails.sample_types.length
                    "
                  >
                    <li
                      v-for="(
                        sampleType, index
                      ) in getPendingOrderDetails.sample_types"
                      :key="index"
                    >
                      <span
                        v-if="
                          sampleType.sample_types &&
                          sampleType.sample_types.length
                        "
                      >
                        <span
                          v-for="(sample, index) in sampleType.sample_types"
                          :key="index"
                        >
                          {{ sample }}
                          <span
                            v-if="index + 1 < sampleType.sample_types.length"
                            >,</span
                          >
                        </span>
                      </span>
                      <span v-else> -- </span>
                    </li>
                  </ul>
                  <ul v-else>
                    --
                  </ul>
                </div>
              </div>
            </div>
          </el-col>
          <el-col
            :xl="3"
            :lg="4"
            :md="8"
            :sm="12"
            :xs="12"
            v-if="
              hadClinicalChemistry &&
              (getPendingOrderDetails.is_fasting == false ||
                getPendingOrderDetails.is_fasting == true)
            "
          >
            <div class="input-value view-cases">
              <h4 class="name">Fasting</h4>
              <p class="value">
                {{ getPendingOrderDetails.is_fasting ? "Yes" : "No" }}
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";
export default {
  data() {
    return {
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
    };
  },
  computed: {
    ...mapGetters("clientPendingOrders", ["getPendingOrderDetails"]),
    hadClinicalChemistry() {
      if (
        this.getPendingOrderDetails &&
        this.getPendingOrderDetails.case_types &&
        this.getPendingOrderDetails.case_types.indexOf("CLINICAL_CHEMISTRY") >
          -1
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>